(function ($) {
  $(document).ready(function () {
    $('a[href^="#"]:not(a.menu-trigger,#back_to_top a)').on("click", function (
      e
    ) {
      var $href = $(this).attr("href"),
        $tgt = $($href == "#" || $href == "" ? "html" : $href),
        $pos = $tgt.offset().top,
        $correctH = 65;

      if (window.innerWidth >= 990) {
        if ($(".js-fix").hasClass("fixed")) {
          $correctH = 90;
        } else {
          $correctH = 200;
        }
      }

      $("html, body").animate(
        {
          scrollTop: $pos - $correctH,
        },
        500
      );
      e.preventDefault();
    });

    /*var mql = window.matchMedia("(min-width : 1000px)");
    mql.addListener(menuClose);
    menuClose(mql);

    function menuClose(mql) {
      if (mql.matches) {
        $("#menu").removeClass('menu_open');
        $('.menu-trigger').removeClass('triggeractive');
      }
    }*/

    $(".slider").slick({
      autoplay: true,
      pauseOnFocus: false,
      pauseOnHover: false,
      pauseOnDotsHover: false,
      draggable: false,
      swipe: false,
      arrows: false,
      fade: true,
      autoplaySpeed: 6000,
      speed: 0,
    });

    $(".gallery").modaal({
      type: "image",
    });
  });
})(jQuery);

window.addEventListener("DOMContentLoaded", function () {
  //set noopener

  var $links = document.querySelectorAll('a[target="_blank"]');
  [].forEach.call($links, function (link) {
    if (link.hasAttribute("rel") === false) {
      link.setAttribute("rel", "noopener");
    }
  });

  //hmb

  function gnavi() {
    const toggle = document.getElementById("toggle");

    if (!toggle) {
      return;
    }

    const menu = document.getElementById("menu");
    const body = document.querySelector("body");

    toggle.addEventListener("click", function (e) {
      menu.style.transition = "all 0.5s ease-in-out";
      e.preventDefault();
      body.classList.toggle("menu-open");
    });

    window.addEventListener("resize", function () {
      if (window.innerWidth >= 990) {
        menu.style.transition = "none";
        body.classList.remove("menu-open");
      }
    });

    menu.addEventListener("click", function (e) {
      if (window.innerWidth < 990) {
        e.preventDefault();
        body.classList.remove("menu-open");
      }
    });

    const menuWrap = document.querySelector("#menu .menu-wrap");
    menuWrap.addEventListener("click", function (e) {
      e.stopPropagation();
    });

    const addSnsLink = () => {
      const snsList = document.getElementById("js-sns");

      if (snsList !== null) {
        const snsHtml = snsList.innerHTML;
        const li = document.createElement("li");
        li.classList.add("sns");
        li.innerHTML = snsHtml;
        menuWrap.appendChild(li);
      }
    };
    addSnsLink();
  }

  gnavi();

  objectFitImages(".ofi");

  function linkInSite() {
    const hash = location.hash;
    if (hash !== "") {
      window.scrollBy(0, -60);
    }
  }
  window.addEventListener("load", linkInSite);
});

window.addEventListener("load", function () {
  const loading = document.querySelector("#loading");
  loading.classList.add("finished");
});

//fixed

function headerFix() {
  const navigationFix = document.querySelector(".navigation__fix");
  let scr;
  let tgtPosition;

  function addFixed() {
    scr = window.pageYOffset;
    tgtPosition = window.innerHeight;
    if (window.innerWidth >= 990) {
      if (scr > tgtPosition) {
        navigationFix.classList.add("fixed");
      } else {
        navigationFix.classList.remove("fixed");
      }
    }
  }
  addFixed();

  window.addEventListener("scroll", addFixed);
  window.addEventListener("load", addFixed);
}
headerFix();

function parallax() {
  const parallax = document.querySelectorAll(".parallax");

  const options = {
    root: null,
    rootMargin: "100px  0px -100px",
    transhold: 0,
  };

  const observer = new IntersectionObserver(doWhenIntersect, options);

  [...parallax].forEach((elm) => {
    observer.observe(elm);
  });

  function doWhenIntersect(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("parallaxActive");
      } else {
        entry.target.classList.remove("parallaxActive");
      }
    });
  }
}
parallax();

class RemoveClassBlog {
  //管理画面のメニューでトップページリンクにhomeクラスを付ける
  constructor(tgtLocation) {
    this.current = document.querySelector(
      `${tgtLocation} .menu__wrap .current`
    );
    this.tgt = document.querySelector(`${tgtLocation} .menu__wrap .blog`);
    this.removeClass = window.addEventListener(
      "DOMContentLoaded",
      this._removeClass.bind(this)
    );
  }
  _removeClass() {
    if (this.current !== null) {
      if (this.current.classList.contains("home")) {
        this.tgt.classList.remove("blog");
      }
    }
  }
}
new RemoveClassBlog(".header");

class RemoveThumb {
  constructor(els) {
    this.els = document.querySelectorAll(els);
    this.remove = window.addEventListener(
      "DOMContentLoaded",
      this._remove.bind(this)
    );
  }
  _remove() {
    if (this.els.length > 0) {
      [...this.els].forEach((el) => {
        const thumb = el.querySelector(".posts__list__item__img");
        if (thumb.innerHTML.includes("noimage")) {
          thumb.parentNode.removeChild(thumb);
        }
      });
    }
  }
}
//new RemoveThumb(".unnecessary-noimage");

class RemovePostImg {
  constructor(els) {
    this.els = document.querySelectorAll(els);
    this.removePostImg = window.addEventListener(
      "DOMContentLoaded",
      this._removePostImg.bind(this)
    );
  }

  _removePostImg() {
    [...this.els].forEach((el) => {
      const postImgs = el.querySelectorAll("img");
      if ([...postImgs].length > 0) {
        [...postImgs].forEach((elImg) => {
          const imgParent = elImg.parentNode;
          imgParent.parentNode.removeChild(imgParent);
        });
      }
    });
  }
}
new RemovePostImg(".js-remove-post-img");

class AddColorToCategories {
  constructor(els) {
    this.els = document.querySelectorAll(els);
    this.action = window.addEventListener(
      "DOMContentLoaded",
      this._addColorTocategories.bind(this)
    );
  }

  _addColorTocategories() {
    [...this.els].forEach((el) => {
      const elCat = el.innerHTML;

      switch (elCat) {
        case "Green":
          el.classList.add("cat-a");
          break;
        case "Workshop":
          el.classList.add("cat-a");
          break;
        case "Marche":
          el.classList.add("cat-b");
          break;
        case "Other event":
          el.classList.add("cat-c");
          break;
        case "Information":
          el.classList.add("cat-d");
          break;
        default:
          el.classList.add("cat-e");
          break;
      }
    });
  }
}

new AddColorToCategories(".post-categories a");
